<template>
  <div>
    <el-button
      class="dayin"
      v-print="'#printMe'"
      ref="inputResult"
      type="primary"
      >打印按钮</el-button
    >
    <div >
      <div class="booklid_wrap">
      
        <el-input
          placeholder="输入图书名"
          v-model.trim="queryInfo.bookName"
          class="ipt_Submit ipt1"
        ></el-input>
          <el-input
          placeholder="输入图书书号"
          v-model.trim="queryInfo.isbnCode"
          onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
          class="ipt_Submit ipt1"
        ></el-input>
          <el-input
          placeholder="输入借阅人"
          v-model.trim="queryInfo.memberName"
          class="ipt_Submit ipt1"
        ></el-input>
          <el-input
          placeholder="输入会员编号"
          v-model.trim="queryInfo.memberCode"
          class="ipt_Submit ipt1"
        ></el-input>
          <el-input
          placeholder="输入订单号"
          v-model.trim="queryInfo.orderNum"
          class="ipt_Submit ipt1"
        ></el-input>
       
      </div>
      <div class="booklid_wrap">
        <div class="block">
          <el-date-picker
            v-model="timeinterval"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
         <button
          class="btn_Submit"
          @click="bookReport(1)"
          v-on:keyup.enter="bookReport(1)"
        >
          查询
        </button>
         <div class="clear"
          @click="clear()"
        >
            <img src="@/assets/img/clear.png" alt="">
             <el-button
                class="clearbtn"
                type="text"
                size="mini"
              >
                清除条件</el-button
              >
        </div>
        <div class="flex export">
          <div>
            <img src="@/assets/img/edit.png" alt="">
          </div>
          <template> <download-excel
            class = "export-excel-wrapper"
            :data = "json_data"
            :fields = "json_fields"
            name = "借书订单.xls">
            <!-- 上面可以自定义自己的样式，还可以引用其他组件button -->
            <el-button
              class="clearbtn"
              type="text"
              size="mini"
            >
              导出EXCEL</el-button
            >
          </download-excel></template>
         
        </div>
      </div>
    </div>
    <el-card>
      
      <el-table
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        :data="tableData"
      >
        <el-table-column prop="createTime" label="借阅时间" width="180">
        </el-table-column>
        <el-table-column prop="orderNum" label="订单号" width="205">
        </el-table-column>
        <el-table-column prop="memberCode" label="会员编号" >
        </el-table-column>
        <el-table-column prop="memberName" label="借书人" width="180">
        </el-table-column>
        <el-table-column prop="memberAddressInfo" label="地址" width="240">
        </el-table-column>
        <el-table-column prop="memberPhoneNum" label="联系电话" width="180">
        </el-table-column>
        <el-table-column prop="orderType" label="订单类型" width="120">
        </el-table-column>
        <el-table-column prop="paymentType" label="支付方式">
        </el-table-column>
        <el-table-column prop="expressFee" label="客户自付邮费" width="120">
        </el-table-column>
        <el-table-column prop="actualExpressFee" label="实际邮费" >
        </el-table-column>
        <el-table-column prop="trackingNum" label="快递单号" width="180">
        </el-table-column>
        <el-table-column prop="status" label="订单状态">
        </el-table-column>
         <el-table-column prop="refundStatus" label="退款状态">
        </el-table-column>
         <el-table-column prop="refundTitle" label="退款理由" width="110">
        </el-table-column>
         <el-table-column prop="refundTime" label="退款时间"  width="160">
        </el-table-column>
        <el-table-column prop="" label="操作">
           <template slot-scope="scope">
                <el-button
                  class="btn_right"
                  type="text"
                  size="mini"
                  @click="showloeding(scope.row,scope.$index)"
                >
                  详情</el-button
                >
              </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="1"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <!-- 详情 -->
      <el-dialog :title="lodingtext" :visible.sync="dia" width="55%">
        <el-table
          :data="detailsData.orderSaleInfoReportSaleVOList"
        >
          <el-table-column prop="bookName" label="书名" width="220">
          </el-table-column>
          <el-table-column prop="isbnCode" label="ISBN" width="180">
          </el-table-column>
          <el-table-column prop="bookCondition" label="品相" >
          </el-table-column>
          <el-table-column prop="account" label="图书押金" >
          </el-table-column>
          <el-table-column prop="" label="操作" >
            <template slot-scope="scope">
              <el-button
                  type="text"
                  class="btn_right"
                  size="mini"
                  @click="uplodeQR(scope.row)"
                  >打印书籍码</el-button
                >
              </template>
          </el-table-column>
        </el-table>
        <div class="flex">
          <p class="price">总原价：{{detailsData.totalBookPrice}}</p>
          <el-button type="primary" class="BGcolor" @click="closedia"
              >确 定</el-button>
        </div>
      </el-dialog>
        
        
    </el-card>
    <el-dialog
      title="点击可下载二维码"
      :visible.sync="QRbook"
      :modal="false"
      width="30%"
      top="-100vh"
    >
      <div class="Qr_wrap" id="printMe" ref="printMe">
        <div class="Qr_wrap_chiren1" style="display: none">
          <div class="qrcode" id="qrcode" ref="qrCodeUrl"></div>
          <div class="book_text">{{ QR_name }}</div>
        </div>
        <div class="Qr_wrap_chiren1">
          <div>
            <img :src="QR_img" alt="" />
          </div>
          <div class="book_text">{{ QR_name }}</div>
        </div>
        <div class="Qr_wrap_chiren2">
          <div>
            <img :src="QR_img" alt="" />
          </div>
          <div class="book_text">{{ QR_name }}</div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="QRbook = false">取 消</el-button>
        <el-button type="primary" @click="PrintQR">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import html2canvas from "html2canvas"; // 转图片打印需要先安装html2Canvas和print-js
import printJS from "print-js";
export default {
    data() {
      return {
        tableData: [],
        detailsData: [],
        dia:false,
        lodingtext:"详情",
        timeinterval:"",
        queryInfo: {
          current: 1,
          size: 10,
          bookName:'',
          isbnCode:'',
          memberName:'',
          memberCode:'',
          orderNum:'',
          startDate:'',
          endDate:'',
        },
  
        currentPage: 1,
        total: 0,
        loading: true, //加载中

        title: "借书订单",
        json_fields: {
        "借阅时间": "createTime",    //常规字段
        "订单号": "orderNum", //支持嵌套属性
        "会员编号": "memberCode", //支持嵌套属性
        "借书人": "memberName", //支持嵌套属性
        "地址": "memberAddressInfo", //支持嵌套属性
        "联系电话": "memberPhoneNum", //支持嵌套属性
        "订单类型": "orderType", //支持嵌套属性
        "支付方式": "paymentType", //支持嵌套属性
        "书籍总价": "totalBookPrice", //支持嵌套属性
        "客户自付邮费": "expressFee", //支持嵌套属性
        "实际邮费": "actualExpressFee", //支持嵌套属性
        "快递单号": "trackingNum", //支持嵌套属性
        "订单状态": "status", //支持嵌套属性
        "退款状态": "refundStatus", //支持嵌套属性
        "退款理由": "refundTitle", //支持嵌套属性
        "退款时间": "refundTime", //支持嵌套属性
      },
      json_data: [
      ],
      // 下载二维码弹出层
      QRbook: false,
      //二维码生成连接
      QR_img: "",
      //二维码第一次生成。因为dom不能清空。所以第一次保留。
      domporp: 0,
      QR_name: "",
      };
    },
    mounted() {
      this.bookReport(1); //获取提现订单列表
    
      // console.log(JsonExcel)
    },
  
    methods: {
      // 0.获取借书订单列表
      async bookReport(e) {
         if(e==1){
          this.queryInfo.current=1;
        }
        if(this.timeinterval!=''){
          let startDate = this.time(this.timeinterval[0])
          let endDate = this.time(this.timeinterval[1])
          this.startDate=startDate;
          // this.startDate=startDate.slice(0,10);
          this.endDate=endDate;
        }
        let params ={
          current:this.queryInfo.current,
          size:this.queryInfo.size,
          bookName:this.queryInfo.bookName,
          isbnCode:this.queryInfo.isbnCode,
          memberName:this.queryInfo.memberName,
          memberCode:this.queryInfo.memberCode,
          orderNum:this.queryInfo.orderNum,
          startDate:this.startDate,
          endDate:this.endDate,
        }
        let { data } = await this.$http.post("orderSaleReport", params);
          data.data.list =  data.data.list.map(item => {
       
          if(item.paymentType==1){
            item.paymentType = '零钱支付';
          }else if(item.paymentType==2){
            item.paymentType = '微信支付';
          }else if(item.paymentType==3){
            item.paymentType = '现金支付';
          }else if(item.paymentType==4){
            item.paymentType = '微信扫码';
          }else if(item.paymentType==5){
            item.paymentType = '支付宝扫码';
          }else{
            item.paymentType = ' ';
          }
          if(item.refundStatus==0){
            item.refundStatus = '退款中';
          }else if(item.refundStatus==1){
            item.refundStatus = '退款成功';
          }else if(item.refundStatus==2){
            item.refundStatus = '退款失败';
          }
          if(item.status==0){
            item.status = '待付款';
          }else if(item.status==1){
            item.status = '打包发货中';
          }else if(item.status==2){
            item.status = '待发货';
          }else if(item.status==3&&item.orderType==2){
            item.status = '待收货';
          }else if(item.status==3&&item.orderType!=2){
            item.status = '待自提';
          }else if(item.status==4){
            item.status = '已取书';
          }else if(item.status==5){
            item.status = '已还书';
          }else{
            item.status = '关闭订单';
          }
          if(item.orderType==0){
            item.orderType = '线下订单';
          }else if(item.orderType==1){
            item.orderType = '线上自提订单';
          }else{
            item.orderType = '线上邮寄订单';
          }
          return item
        })
        this.tableData = data.data.list;
        this.total = data.data.total;
        this.loading = false;
        this.excel(); //获取excel列表
       
      },
      async excel(){
         // excel数据
         let obj ={
          table:0,
          orderSaleReportDto:{
            bookName:this.queryInfo.bookName,
            isbnCode:this.queryInfo.isbnCode,
            memberName:this.queryInfo.memberName,
            memberCode:this.queryInfo.memberCode,
            orderNum:this.queryInfo.orderNum,
            startDate:this.startDate,
            endDate:this.endDate,
          }
         
        }
        let { data } = await this.$http.post("excelExport", obj);
        console.log(data);
        data.data =  data.data.map(item => {
          
          if(item.paymentType==1){
            item.paymentType = '零钱支付';
          }else if(item.paymentType==2){
            item.paymentType = '微信支付';
          }else if(item.paymentType==3){
            item.paymentType = '现金支付';
          }else if(item.paymentType==4){
            item.paymentType = '微信扫码';
          }else if(item.paymentType==5){
            item.paymentType = '支付宝扫码';
          }else{
            item.paymentType = ' ';
          }
          if(item.refundStatus==0){
            item.refundStatus = '退款中';
          }else if(item.refundStatus==1){
            item.refundStatus = '退款成功';
          }else if(item.refundStatus==2){
            item.refundStatus = '退款失败';
          }
          if(item.status==0){
            item.status = '待付款';
          }else if(item.status==1){
            item.status = '打包发货中';
          }else if(item.status==2){
            item.status = '待发货';
          }else if(item.status==3&&item.orderType==2){
            item.status = '待收货';
          }else if(item.status==3&&item.orderType!=2){
            item.status = '待自提';
          }else if(item.status==4){
            item.status = '已取书';
          }else if(item.status==5){
            item.status = '已还书';
          }else{
            item.status = '关闭订单';
          }
          if(item.orderType==0){
            item.orderType = '线下订单';
          }else if(item.orderType==1){
            item.orderType = '线上自提订单';
          }else{
            item.orderType = '线上邮寄订单';
          }
          if(item.trackingNum!=null){
            item.trackingNum="/"+item.trackingNum
          }
          
          return item
        })
        this.json_data = data.data;
      },
      // 详情 1
      async showloeding(item, index) {
        this.item = item;
        this.lodingtext = item.orderNum;
        let params ={
          orderNum:item.orderNum,
        }
        let { data } = await this.$http.post("orderSaleInfoReport", params);
        console.log(data.data);
        for(let i in data.data.orderSaleInfoReportSaleVOList){
          if(data.data.orderSaleInfoReportSaleVOList[i].bookCondition==0){
            data.data.orderSaleInfoReportSaleVOList[i].bookCondition = '全新';
          }else if(data.data.orderSaleInfoReportSaleVOList[i].bookCondition==1){
            data.data.orderSaleInfoReportSaleVOList[i].bookCondition = '良好';
          }else{
            data.data.orderSaleInfoReportSaleVOList[i].bookCondition = '普通';
          }
        }
        this.detailsData =data.data
        this.lodingStyle = 1;
        this.item_index = index;
        this.dia = true;
      },
      clear(){
        this.queryInfo.bookName=''
        this.queryInfo.isbnCode=''
        this.queryInfo.memberName=''
        this.queryInfo.memberCode=''
        this.queryInfo.orderNum=''
        this.startDate=''
        this.endDate=''
        this.timeinterval=''
        this.excel(); //获取excel列表
      },
      closedia(){
        this.dia = false;
      },
          // 下载书架 和 分区 的二维码  ---------------------------------------为新增逻辑，与其他代码无关连
      uplodeQR(FQitem) {
      //  console.log(this.item);
       console.log(FQitem);
        // 1为编辑过后--操作，0为未编辑 --缓存
        this.QR_name = FQitem.bookName;
        console.log(this.QR_name);
        this.QRbook = true;
        // if (this.domporp == 1) {
        //   document.getElementById("qrcode").innerHTML = "";
        // }
        this.printQcode(FQitem.qrCode);
      },
        // ----------打印二维码
    PrintQR() {
      // this.$refs.inputResult.$el.click();
      // html2canvas(this.$refs.printMe, {
      //   backgroundColor: null,
      //   useCORS: true,
      //   windowHeight: document.body.scrollHeight,
      // }).then((canvas) => {
      //   const url = canvas.toDataURL();
      //   this.img = url;
      //   printJS({
      //     printable: url,
      //     type: "image",
      //     documentTitle: "打印图片",
      //   });
      // });
    },
          // 生成二维码
      printQcode(result) {
        let https = result;
        let QR = JSON.stringify(https);
        QR = QR.replace(/^\"|\"$/g, "");
        this.$nextTick(function () {
          var qrcode = new QRCode(this.$refs.qrCodeUrl, {
            text: QR,
            width: 120,
            height: 120,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });
          this.domporp = 1; //以后每次都情空dom
        });

        // 复制一份
        setTimeout(() => {
          let s = this.$refs.qrCodeUrl.childNodes.length;
          //  该位置赋值图片暂时无法清除，现默认取最后一条
          let img = this.$refs.qrCodeUrl.childNodes[s - 1].getAttribute("src");
          this.QR_img = img;
          // console.log(this.QR_img);
          this.$refs.inputResult.$el.click();
        }, 10);
      },
      time(date) {
          var y = date.getFullYear()
          var m = date.getMonth() + 1
          m = m < 10 ? '0' + m : m
          var d = date.getDate()
          d = d < 10 ? '0' + d : d
          var h = date.getHours()
          h = h < 10 ? '0' + h : h
          var minute = date.getMinutes()
          minute = minute < 10 ? '0' + minute : minute
          var second = date.getSeconds()
          second = second < 10 ? '0' + second : second
          return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second
      },
      // 3.分页
      handleSizeChange(val) {
        this.queryInfo.size = val;
        this.bookReport(0);
      },
      handleCurrentChange(val) {
        this.queryInfo.current = val;
        this.bookReport(0);
      },
    },
  };
</script>

<style scoped>
.flex{
  display: flex;
}
.booklid_wrap {
  /* width: 100%; */

  display: flex;
  margin-bottom: 21px;
  /* justify-content: space-between; */
}


.ipt_Submit {
  width: 200px;
}

.ipt_Submit ::v-deep input {
  height: 35px;
}

.btn_Submit {
  margin-left: 10px;
}
.price{
  margin-top: 36px;
  margin-left: 10px;
}
.BGcolor{
  margin-left: 73%;
  margin-top: 36px;
}
.ipt1 {
  margin-right: 10px;
}
.el-table{
  color: #333333;
}
::v-deep.el-table th > .cell {
  text-align: center;
}

::v-deep.el-table .cell {
  text-align: center;
}
.clear{
  display: flex;
  margin-top: 9px;
  margin-left: 22px;
  
}
.clearbtn{
  color: #999999;
  padding-top: 0;
  font-size: 18px;
}
.clear img{
  margin-top: 2px;
  width: 16px;
  height: 16px;
}
.export{
  margin-top: 11px;
  color: #999999;
  margin-left: 22px;
}
.export img{
  margin-top: 2px;
  width: 16px;
  height: 16px;
}
.dayin {
  position: relative;
  z-index: -10;
}
::v-deep .el-table__expanded-cell {
  padding: 0;
  padding-left: 2%;
}

.el-button--primary {
  background-color: rgba(221, 2, 27, 1);
  color: #fff;
  border-color: rgba(221, 2, 27, 1);
}
.qrcode {
  width: 120px;
  height: 120px;
  margin: 0 auto;
}
.book_text {
  margin-top: 15px;
  width: 2000px;
}
.Qr_wrap {
  position: relative;
  height: 150px;
  width: 100%;

  text-align: center;
}

.Qr_wrap_chiren1 {
  position: absolute;
  left: 0;
}

.Qr_wrap_chiren2 {
  position: absolute;
  right: 0;
}
</style>