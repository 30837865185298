<template>
  <div>
    <!-- 书架添加 书架按钮 -->
    <el-button
      @click="upBookShelf"
      style="background-color: #ff1932; color: #fff"
      >添加书架</el-button
    >
    <el-button
      class="dayin"
      v-print="'#printMe'"
      ref="inputResult"
      type="primary"
      >打印按钮</el-button
    >
    <!-- this.$refs.inputResult.$el.click(); -->
    <!-- 书架内容区 -->
    <el-card style="margin-top: 10px">
      <el-table
        :data="tableData"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        style="width: 100%"
        ref="table"
        :highlight-current-row="true"
        :row-key="getRowKeys"
        :expand-row-keys="expands"
      >
        <el-table-column type="index" label="序号" width="200">
        </el-table-column>
        <el-table-column prop="bookShelfName" label="书架名称">
          <template slot-scope="scope">
            <div
              style="
                margin-top: 1px;
                margin-right: 10px;
                display: inline-block;
                vertical-align: sub;
              "
            >
              <img
                v-show="scope.row.ChirendShow"
                src="@/assets/img/push.svg"
                @click="pushitem(scope.row, scope.$index)"
                alt=""
              />
              <img
                v-show="!scope.row.ChirendShow"
                src="@/assets/img/del.svg"
                @click="pushitem(scope.row, scope.$index)"
                alt=""
              />
            </div>
            <span>{{ scope.row.bookShelfName }}</span>
          </template>
        </el-table-column>

        <!-- <el-table-column prop="inventory" label="库存"> </el-table-column> -->

        <el-table-column prop="author" label="操作">
          <template slot-scope="scope" style="padding-left: 10px">
            <el-button
              class="btn_right"
              type="text"
              size="mini"
              @click="showloeding(scope.row, scope.$index)"
            >
              编辑</el-button
            >
            <!--  v-if="scope.row.bookInfoChildrenList.length != 0" -->
            <el-button
              type="text"
              class="btn_right"
              size="mini"
              @click="handleToogleExpand(scope.row, scope.$index)"
              >添加分区</el-button
            >

            <el-button type="text" size="mini" @click="dellogding(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
        <el-table-column type="expand" width="1">
          <!-- 分区 -->
          <template>
            <el-table
              :data="childrenData"
              :show-header="false"
              style="width: 100%"
            >
              <el-table-column width="240"></el-table-column>
              <el-table-column prop="partitionName"></el-table-column>
              <!-- <el-table-column prop="inventory"> </el-table-column> -->
              <el-table-column prop="author">
                <template slot-scope="scope">
                  <el-button
                    class="btn_right"
                    type="text"
                    size="mini"
                    @click="upchirenloeding(scope.row)"
                  >
                    编辑</el-button
                  >
                  <el-button
                    type="text"
                    class="btn_right"
                    size="mini"
                    @click="uplodeQR(scope.row)"
                    >打印书架码</el-button
                  >

                  <el-button
                    type="text"
                    size="mini"
                    @click="delchirenloeding(scope.row)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </template>

          <!-- 分区 end-->
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.current"
          :page-sizes="[10, 15, 20]"
          :page-size="queryInfo.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>

      <!-- 书架 -->
      <el-dialog :title="lodingtext" :visible.sync="dia" width="30%">
        <el-form :model="form">
          <el-form-item :label="inoputNane" label-width="80px">
            <el-input
              v-model="form.bookShelfName"
              autocomplete="off"
              maxlength="10"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dia = false">取 消</el-button>
          <el-button type="primary" class="BGcolor" @click="updataBook"
            >确 定</el-button
          >
        </span>
      </el-dialog>

      <!-- 分区 -->
      <el-dialog :title="lodingtext" :visible.sync="dia2" width="30%">
        <el-form :model="form2">
          <el-form-item :label="inoputNane" label-width="80px">
            <el-input
              v-model="form2.partitionName"
              autocomplete="off"
              maxlength="10"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dia2 = false">取 消</el-button>
          <el-button type="primary" class="BGcolor" @click="updataBook2"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </el-card>

    <!-- 书架 下载二维码 弹出层 -->
    <!-- top="-100vh" -->
    <el-dialog
      title="点击可下载二维码"
      :visible.sync="QRbook"
      :modal="false"
      width="30%"
      top="-100vh"
    >
      <div class="Qr_wrap" id="printMe" ref="printMe">
        <div class="Qr_wrap_chiren1" style="display: none">
          <div class="qrcode" id="qrcode" ref="qrCodeUrl"></div>
          <div class="book_text">{{ QR_name }}</div>
        </div>
        <div class="Qr_wrap_chiren1">
          <div>
            <img :src="QR_img" alt="" />
          </div>
          <div class="book_text">{{ QR_name }}</div>
        </div>
        <div class="Qr_wrap_chiren2">
          <div>
            <img :src="QR_img" alt="" />
          </div>
          <div class="book_text">{{ QR_name }}</div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="QRbook = false">取 消</el-button>
        <el-button type="primary" @click="PrintQR">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// 存储当前修改项的id, 防止详情页刷新时， 页面出现空屏

// 引入二维码生成插件
import QRCode from "qrcodejs2";
import html2canvas from "html2canvas"; // 转图片打印需要先安装html2Canvas和print-js
import printJS from "print-js";
export default {
  data() {
    return {
      tableData: [],

      // 查询参数对象----分页
      queryInfo: {
        current: 1,
        size: 10,
      },
      // 遮罩层
      loading: true,
      total: 0,

      // form 新整书架
      form: {},
      dia: false,

      // 书架 分区
      form2: {},
      dia2: false,

      // 下载二维码弹出层
      QRbook: false,
      //二维码生成连接
      QR_img: "",
      //二维码第一次生成。因为dom不能清空。所以第一次保留。
      domporp: 0,
      //书架名称
      QR_name: "",

      // 弹窗标题
      lodingtext: "添加书架",
      // inupt名称
      inoputNane: "书架名称",
      // 1:编辑书架 2 添加分区  0 添加书架
      lodingStyle: 1,

      // 分区数组
      childrenData: [],

      // 当前点击书架项
      item: {},
      item_key: "",

      // 编辑书架名称时 新整辅助字段。判断页面是否是第一次展开。 如果是缓存则为0，点击展开为1
      item_type: 0,

      // 分区 当前点击的分区
      fen_delitem: {},
      getRowKeys(row) {
        return row.id;
      },
      expands: [],
    };
  },

  created() {
    //  let id = this.$route.params.id;
    let queryInfo = this.$route.params.queryInfo;
    if (queryInfo != undefined) {
      this.queryInfo = queryInfo;
    }
    this.getDataList();

    // 点击事件
    this.keyupSubmit();
  },

  mounted() {
    // 在这里你想初始化的时候展开哪一行都可以了
    let item_key = sessionStorage.getItem("book_key");
    if (!item_key) return false;
    setTimeout(() => {
      this.expands.push(this.tableData[item_key].id);
      this.childrenData = this.tableData[item_key].bookShelfPartitionList;
    }, 1000);
  },
  methods: {
    // 绑定Enter事件
    keyupSubmit() {
      document.onkeydown = (e) => {
        let _key = window.event.keyCode;
        if (_key === 13) {
          if (this.dia == true) {
            this.updataBook();
          } else if (this.dia2 == true) {
            this.updataBook2();
          }
        }
      };
    },

    // ******************************************************************
    async getDataList() {
      const { data: res } = await this.$http.get(
        "getAllBookShelf/" + this.queryInfo.current + "/" + this.queryInfo.size
      );
      this.total = res.data.total;
      this.tableData = res.data.records;
      this.tableData.forEach((v) => {
        v.ChirendShow = true;
      });
      this.loading = false;
    },

    upBookShelf() {
      this.lodingStyle = 0;
      (this.lodingtext = "添加书架"),
        // inupt名称
        (this.inoputNane = "书架名称"),
        (this.form = {});
      this.dia = true;
    },

    // 5.0弹出层提交按钮
    async updataBook() {
      this.form.siteId = 1;
      sessionStorage.setItem("book_key", this.item_key);
      // 5.1编辑书架
      if (this.form.bookShelfName == "" || this.form.bookShelfName == undefined)
        return this.$message.error("输入内容不能为空");
      if (this.lodingStyle == 1) {
        this.form.siteId = 1;
        delete this.form.ChirendShow;
        const { data: res } = await this.$http.post(
          "updateBookShelf",
          this.form
        );
        let msg = "编辑成功";
        let err = "失败";
        if (res.ok) {
          // console.log(this.form);
          // this.$set(this.tableData, this.item_index, this.form);
          this.tableData[this.item_index].bookShelfName =
            this.form.bookShelfName;
          this.$message.success(msg);
          this.dia = false;
        } else {
          this.$message.error(err);
        }

        // 添加分区
      } else if (this.lodingStyle == 2) {
        let prams = {};
        let name = "";
        prams.bookShelfId = this.form.id;
        name = this.form.partitionName;
        prams.bookShelfName = this.form.bookShelfName;
        prams.partitionName = prams.bookShelfName;
        prams.bookShelfName = name;
        const { data: res } = await this.$http.post(
          "saveBookShelfPartition",
          prams
        );
        let msg = "编辑成功";
        let err = "失败";
        let partition = "fenqu";
        // console.log(res, prams);
        prams.id = res.data;
        prams.inventory = res.inventory;
        prams.lastTime = res.lastTime;
        this.elmMessage(res, msg, err, partition, prams);

        // 新增书架
      } else if (this.lodingStyle == 0) {
        this.form.siteId = 1;
        const { data: res } = await this.$http.post("saveBookShelf", this.form);
        let msg = "添加成功";
        let err = "失败";
        if (res.ok) {
          let item = JSON.parse(JSON.stringify(this.tableData[0]));
          item.bookShelfPartitionList = [];
          item.id = res.data;
          item.bookShelfName = this.form.bookShelfName;
          this.dia = false;
          this.$message.success(msg);
          this.getDataList();
        } else {
          this.$message.error(err);
        }
      }

      // const { data: res } = await this.$http.post("saveBookShelf", this.form);
    },

    async updataBook2() {
      if (
        this.form2.partitionName == "" ||
        this.form2.partitionName == undefined
      )
        return this.$message.error("输入内容不能为空");
      const { data: res } = await this.$http.post(
        "updateBookShelfPartition",
        this.form2
      );
      let msg = "编辑成功";
      let err = "失败";

      if (res.ok) {
        let fen_key = this.childrenData.findIndex((v) => {
          return v.id == this.form2.id;
        });
        this.$set(this.childrenData, fen_key, this.form2);
        this.$message.success(msg);
      } else {
        this.$message.error(err);
      }

      this.dia2 = false;
    },

    elmMessage(res, msg, err, y = "Refresh", x = " ") {
      if (res.ok) {
        this.dia = false;
        if (y == "Refresh") {
          this.getDataLis();
        } else {
          this.item.bookShelfPartitionList.unshift(x);
          this.$set(this.tableData, this.item, this.item_key);
        }

        this.$message.success(msg);
      } else {
        this.$message.error(err);
      }
    },

    // 编辑书架 1
    showloeding(item, index) {
      this.item = item;
      this.item_type=1, //编辑过书架
      this.lodingtext = "编辑书架";
      this.inoputNane = "书架名称";
      this.lodingStyle = 1;
      this.item_index = index;
      this.form = JSON.parse(JSON.stringify(item));
      this.dia = true;
    },

    // 添加分区 2
    handleToogleExpand(item, index) {
      this.lodingtext = "添加分区";
      this.inoputNane = "分区名称";
      this.lodingStyle = 2;

      // 当前点击项
      this.item = item;
      this.item_key = index;

      this.form = JSON.parse(JSON.stringify(item));
      this.form.partitionName = this.form.bookShelfName;
      this.dia = true;
      this.form.bookShelfName = "";
    },

    // 编辑分区
    upchirenloeding(item) {
      this.lodingtext = "编辑分区";
      this.inoputNane = "分区名称";
      this.lodingStyle = 3;
      this.form2 = JSON.parse(JSON.stringify(item));
      this.dia2 = true;
    },

    // 下载书架 和 分区 的二维码  ---------------------------------------为新增逻辑，与其他代码无关连
    uplodeQR(FQitem) {
    //  console.log(this.item);
    //  console.log(this.item_type);
      // 1为编辑过后--操作，0为未编辑 --缓存
      if (this.item_type == 0) {
        this.QR_name = FQitem.bookShelfName + "-" + FQitem.partitionName;
      } else {
        this.QR_name = this.item.bookShelfName + "-" + FQitem.partitionName;
      }
      this.QRbook = true;
      if (this.domporp == 1) {
        document.getElementById("qrcode").innerHTML = "";
      }
      this.printQcode(FQitem);
    },

    // ----------打印二维码
    PrintQR() {
      // this.$refs.inputResult.$el.click();
      // html2canvas(this.$refs.printMe, {
      //   backgroundColor: null,
      //   useCORS: true,
      //   windowHeight: document.body.scrollHeight,
      // }).then((canvas) => {
      //   const url = canvas.toDataURL();
      //   this.img = url;
      //   printJS({
      //     printable: url,
      //     type: "image",
      //     documentTitle: "打印图片",
      //   });
      // });
    },

    //  下载书架 和 分区 的二维码  ---------------------------------------end
    // 删除 分区
    delchirenloeding(item) {
      this.fen_delitem = item;
      this.$confirm("是否删除该分区?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        confirmButtonClass: "btn_clas",
      })
        .then(async () => {
          const { data: res } = await this.$http.get(
            "removeBookShelfPartition/" + item.id
          );
          if (res.ok) {
            this.getDataList();
            let fen_key = this.childrenData.findIndex((v) => {
              return v.id == this.fen_delitem.id;
            });
            this.childrenData.splice(fen_key, 1);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          } else {
            this.$message({
              type: "error",
              message: res.message,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 删除 书架
    dellogding(item) {
      this.$confirm("是否删除该书架?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        confirmButtonClass: "btn_clas",
      })
        .then(async () => {
          const { data: res } = await this.$http.get(
            "removeBookShelf/" + item.id
          );
          if (res.ok) {
            this.getDataList();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          } else {
            this.$message({
              type: "error",
              message: res.message,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    //替换加减符号
    pushitem(row, index) {
      this.item = row; //存储当前书架的值
      this.tableData.forEach((v) => {
        if (row.id == v.id) {
          v.ChirendShow = !v.ChirendShow;
        } else {
          v.ChirendShow = true;
        }
      });
      let $table = this.$refs.table;
      this.childrenData = row.bookShelfPartitionList;
      for (let i = 0; i < this.childrenData.length; i++) {
        if (!this.childrenData[i].inventory) {
          this.childrenData[i].inventory = 0;
        }
      }
      this.tableData.map((item) => {
        if (row.id != item.id) {
          $table.toggleRowExpansion(item, false);
        }
      });
      $table.toggleRowExpansion(row);
    },

    // 监听分页跳转
    handleSizeChange(val) {
      this.queryInfo.size = val;
      this.getDataList();
    },

    handleCurrentChange(val) {
      this.queryInfo.current = val;
      this.getDataList();
    },

    // ------------------------------------ 二维码  封装 不好提取
    // 生成二维码
    printQcode(result) {
      let https = "shelfid=" + result.bookShelfId + "&partition=" + result.id;
      let QR = JSON.stringify(https);
      QR = QR.replace(/^\"|\"$/g, "");
      this.$nextTick(function () {
        var qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: QR,
          width: 120,
          height: 120,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });
        this.domporp = 1; //以后每次都情空dom
      });

      // 复制一份
      setTimeout(() => {
        let s = this.$refs.qrCodeUrl.childNodes.length;
        //  该位置赋值图片暂时无法清除，现默认取最后一条
        let img = this.$refs.qrCodeUrl.childNodes[s - 1].getAttribute("src");
        this.QR_img = img;
        // console.log(this.QR_img);
        this.$refs.inputResult.$el.click();
      }, 10);
    },
  },
};
</script>


<style lang="scss" scoped>
.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}

.btn_right {
  color: #2689f7;
}

::v-deep .el-table__expanded-cell {
  padding: 0;
  padding-left: 2%;
}

.el-button--primary {
  background-color: rgba(221, 2, 27, 1);
  color: #fff;
  border-color: rgba(221, 2, 27, 1);
}

.qrcode {
  width: 120px;
  height: 120px;
  margin: 0 auto;
}

.book_text {
  margin-top: 15px;
  width: 2000px;
}

.dayin {
  position: relative;
  z-index: -10;
}

.Qr_wrap {
  position: relative;
  // display: flex;
  height: 150px;
  width: 100%;

  text-align: center;
  // justify-content: center;
  // align-items: center;
  // flex-direction: column;
}

.Qr_wrap_chiren1 {
  // flex: 1;
  position: absolute;
  left: 0;
}

.Qr_wrap_chiren2 {
  // flex: 1;
  position: absolute;
  right: 0;
}
</style>

